import RSButton from "ds/components/RSButton";
import { PRIMARY_COLOR } from "ds/materials/colors";
import { useNavigate } from "react-router-dom";

export function RedirectButton({ label, url }: { label: string; url: string }) {
  const navigate = useNavigate();
  return (
    <RSButton
      style={{ color: PRIMARY_COLOR }}
      color="white"
      id="error_redirect_to"
      loading="na"
      variant="contained"
      onClick={() => navigate(url)}
    >
      {label}
    </RSButton>
  );
}
