import Translations from "@recare/translations/types";
import { getErrorMessage } from "../utils/errors";
import { findEmailInMessage } from "../utils/strings";

export const DOMAIN_NAME_REGEX = /\bdomain\s+(\S+)\s/;
export const WHITELISTED_DOMAINS_REGEX = /\[[^\]]+\]/g;
export const WHITELISTED_DOMAIN_REGEX =
  /domain\s+[\w.-]+\s+is\s+not\s+whitelisted/g;

export function getEmailFromAccountCreationError({
  message,
}: {
  message: string;
}) {
  const searchString = "account with email";
  return findEmailInMessage({ message, searchString });
}

export function getEmailFromRoleCreationError({
  message,
  translations,
}: {
  message: string;
  translations: Translations;
}) {
  const searchString = "adding role not permitted - account ";
  const whiteListedEmail = findEmailInMessage({ message, searchString });
  if (whiteListedEmail) {
    return translations.userManagement.errorDomainNotWhitelisted;
  }

  return null;
}

export function matchInputAgainstRegex(
  input: string,
  regex: RegExp,
): string | null {
  const match = input.match(regex);
  return match ? match[0] : null;
}

export function formatDomainError(
  backendError: string,
  translations: Translations,
) {
  const domainNameMatch = backendError.match(DOMAIN_NAME_REGEX);
  if (domainNameMatch) {
    const whitelistedDomains = matchInputAgainstRegex(
      backendError,
      WHITELISTED_DOMAINS_REGEX,
    );

    if (whitelistedDomains) {
      return translations.userManagement.errorDomainNotWhitelisted;
    }
  }

  return null;
}

export function getCreateAccountErrorMessage({
  error,
  translations,
}: {
  error: unknown;
  translations: Translations;
}): string {
  const backendError = (error as any).networkError?.result?.error;

  if (typeof backendError === "string") {
    if (backendError.includes("reached daily limit")) {
      return translations.general.errorRequestLimit;
    }
    if (
      backendError ===
      "could not add domains to whitelist - new domains cannot be added to whitelist"
    ) {
      return translations.userManagement.errorDomainNotWhitelisted;
    }

    const domainError = formatDomainError(backendError, translations);
    if (domainError) return domainError;

    const emailAddress = getEmailFromAccountCreationError({
      message: backendError,
    });
    if (emailAddress) {
      return translations.userManagement.errorEmailAlreadyExists({
        emailAddress,
      });
    }
  }

  return getErrorMessage(error);
}

export function getCreateRolesErrorMessage({
  error,
  translations,
}: {
  error: unknown;
  translations: Translations;
}) {
  const backendError = (error as any).networkError?.result.error;

  if (typeof backendError === "string") {
    if (backendError === "missing roles") {
      // #TODO add translations
      return backendError;
    }

    const whiteListError = getEmailFromRoleCreationError({
      message: backendError,
      translations,
    });

    if (whiteListError) return whiteListError;
  }

  return getErrorMessage(error);
}
