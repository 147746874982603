import { APP_CLINIC } from "@recare/core/consts";
import RenderApp from "containers/Appv4";
import ServiceFactory, { Services } from "containers/ServiceFactory";
import Router from "./Router";

export type AppContext = ReturnType<typeof Context>;

const Context = (services: Services) => ({
  app: APP_CLINIC,
  services,
});

const services = ServiceFactory("Clinic");
const context = Context(services);
RenderApp(context, Router);
