import { SEARCH_TYPE_CARE } from "@recare/core/consts";
import {
  ONTOLOGY_CARE_LEVEL,
  ONTOLOGY_SOLUTION,
  ONTOLOGY_SPECIALIZATIONS,
} from "@recare/core/model/utils/ontologies";
import { grammaticalList } from "@recare/core/model/utils/strings";
import {
  Auction,
  GetOntologyType,
  InfectionAndGerms,
  PatientNeeds,
} from "@recare/core/types";
import Translations from "@recare/translations/types";
import { CHARACTER_LIMIT_DESCRIPTION_TEXT } from "../FormSections/SectionB/TransitionalCareDescription";

function getDescriptionTranslationFromObject<
  T extends InfectionAndGerms | PatientNeeds,
>({
  object,
  translationMap,
  translations,
}: {
  object: T | null | undefined;
  translationMap: Record<keyof T, (translations: Translations) => string>;
  translations: Translations;
}): string {
  if (!object) return "";

  return Object.entries(object)
    .map(([key, value]) => {
      if (!value) return null;

      if (key === "other") {
        return `${translationMap[key as keyof T](translations)}: ${value}`;
      }

      return translationMap[key as keyof T](translations);
    })
    .truthy()
    .join(", ");
}

const CARENEEDS_TRANSLATIONS: Record<
  keyof PatientNeeds,
  (translations: Translations) => string
> = {
  access_drainages: (translations: Translations) =>
    translations.patient.careNeeds.accessDrainages.accessAndDrainages,
  base_care: (translations: Translations) =>
    translations.patient.careNeeds.baseCare,
  basic_medical_care: (translations: Translations) =>
    translations.patient.careNeeds.medicalCare,
  breathing: (translations: Translations) =>
    translations.patient.careNeeds.breathing,
  companionship: (translations: Translations) =>
    translations.patient.careNeeds.companionship,
  compression: (translations: Translations) =>
    translations.patient.careNeeds.compression,
  dialysis: (translations: Translations) =>
    translations.patient.careNeeds.dialysis,
  help_at_home: (translations: Translations) =>
    translations.patient.careNeeds.helpAtHome,
  incontinence: (translations: Translations) =>
    translations.patient.careNeeds.incontinence,
  injection: (translations: Translations) =>
    translations.patient.careNeeds.injection,
  medication: (translations: Translations) =>
    translations.patient.careNeeds.medication,
  monitoring: (translations: Translations) =>
    translations.patient.careNeeds.monitoring,
  nutrition: (translations: Translations) =>
    translations.patient.careNeeds.nutrition,
  palliative: (translations: Translations) =>
    translations.patient.palliativeCare.label,
  psychiatric_care: (translations: Translations) =>
    translations.patient.careNeeds.psychiatric_care.label,
  support: (translations: Translations) =>
    translations.patient.careNeeds.needSupport,
  wound_care: (translations: Translations) =>
    translations.patient.careNeeds.woundCare,
  food: (_: Translations) => "",
  important_information: (_: Translations) => "",
  remarks: (_: Translations) => "",
  static_care_disabled_patients: (_: Translations) => "",
  __typename: (_: Translations) => "",
} as const;

const INFECTION_TRANSLATIONS: Record<
  keyof InfectionAndGerms,
  (translations: Translations) => string
> = {
  clostridien: (translations: Translations) =>
    translations.ontologies.infectionAndGerms.values.clostridien,
  four_mrgn: (translations: Translations) =>
    translations.ontologies.infectionAndGerms.values.four_mrgn,
  mrsa: (translations: Translations) =>
    translations.ontologies.infectionAndGerms.values.mrsa,
  requires_isolation: (translations: Translations) =>
    translations.ontologies.infectionAndGerms.values.requires_isolation,
  three_mrgn: (translations: Translations) =>
    translations.ontologies.infectionAndGerms.values.three_mrgn,
  vre: (translations: Translations) =>
    translations.ontologies.infectionAndGerms.values.vre,
  other: (translations: Translations) => translations.general.other,
  remarks: (_: Translations) => "",
  description: (_: Translations) => "",
  __typename: (_: Translations) => "",
} as const;

export const getTransitionalCareDescriptionText = ({
  auction,
  getOntology,
  translations,
}: {
  auction: Auction;
  getOntology: GetOntologyType;
  translations: Translations;
}) => {
  const fieldObject: Record<string, { title: string; value: string | null }> = {
    location: {
      title: translations.patient.treatmentLocation.title,
      value: `${auction.profile?.search_location?.zipcode}, ${auction.profile?.search_location?.city}`,
    },
    searchRadius: {
      title: translations.patient.searchRadius,
      value: auction.radius_in_meter
        ? `${auction.radius_in_meter / 1000} ${
            translations.abbreviations.kilometers.abbreviation
          }`
        : "",
    },
    carelevel: {
      title: translations.patient.carelevel,
      value: getOntology({
        key: auction.patient?.profile?.financing?.carelevel?.level,
        type: ONTOLOGY_CARE_LEVEL,
      }),
    },
    solution: {
      title: translations.ontologies.solution.key,
      value: grammaticalList({
        array: auction.solutions,
        getOntology,
        ontologyType: ONTOLOGY_SOLUTION,
        translations,
      }),
    },
    specializations: {
      title: translations.ontologies.specializations.key,
      value: grammaticalList({
        array: auction.specializations,
        getOntology,
        ontologyType: ONTOLOGY_SPECIALIZATIONS,
        translations,
      }),
    },
    infectionAndGerms: {
      title: translations.ontologies.infectionAndGerms.key,
      value: getDescriptionTranslationFromObject({
        object: auction.patient.diagnosis?.infection_and_germs,
        translations,
        translationMap: INFECTION_TRANSLATIONS,
      }),
    },
    careNeeds: {
      title:
        auction.search_type === SEARCH_TYPE_CARE
          ? translations.patient.careNeeds.stepperTitleCare
          : translations.patient.careNeeds.stepperTitle,
      value: getDescriptionTranslationFromObject({
        object: auction.patient.care_needs,
        translations,
        translationMap: CARENEEDS_TRANSLATIONS,
      }),
    },
  };

  return Object.values(fieldObject)
    .filter(({ value }) => value != "")
    .map(({ title, value }) => `${title}: ${value}`)
    .join("; ")
    .substring(0, CHARACTER_LIMIT_DESCRIPTION_TEXT);
};
