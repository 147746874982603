import { useTranslations } from "@recare/translations";
import { margin, padding } from "ds/materials/metrics";
import {
  ActivableToggle,
  GAP,
  TextAreaWithLabel,
  TEXT_AREA_MAX_CHARACTERS,
  DatePickerWithLabel,
  SMALL_INPUT_WIDTH,
} from "dsl/ecosystems/PatientForms/components";

export const PatientConfirmation = () => {
  const translations = useTranslations();
  return (
    <ActivableToggle
      elementName="transitional_care_patient_confirmation"
      label={
        translations.patientForms.transitionalCareForm.sectionB
          .toggleConfirmedWithPatient
      }
      sideMutation={(value, mutate) => {
        if (!value) {
          return mutate(null, "transitional_care_patient_confirmation_date");
        }

        return mutate(
          null,
          "transitional_care_patient_confirmation_explanation",
        );
      }}
      wrapperStyle={{ padding: padding(GAP, 0, 0) }}
      componentIfFalse={
        <TextAreaWithLabel
          elementName="transitional_care_patient_confirmation_explanation"
          label={
            translations.patientForms.transitionalCareForm.sectionB
              .textFieldExplanationLabel
          }
          marginOverride={margin(0)}
          multiline
          characterLimit={TEXT_AREA_MAX_CHARACTERS}
          required
        />
      }
      componentIfTrue={
        <DatePickerWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.sectionB
              .dateFieldLabel
          }
          elementName="transitional_care_patient_confirmation_date"
          wrapperSx={{ width: "100%" }}
          inputSx={{ width: SMALL_INPUT_WIDTH }}
        />
      }
    />
  );
};
