import {
  CANDIDATES_STATUS_SUCCESS,
  PATIENT_ELIGIBILITY_APPROVED,
  PATIENT_ELIGIBILITY_IN_PROCESS,
  PATIENT_ELIGIBILITY_REJECTED,
  PATIENT_TAB_FILES,
  PATIENT_TAB_INSURANCE,
  PATIENT_TAB_MAP,
  PATIENT_TAB_PROFILE,
  PATIENT_TAB_REHAB_FORMS,
  PATIENT_TAB_SEARCH,
  PATIENT_TAB_TIMELINE,
  PATIENT_TAB_TRANSITIONAL_CARE,
  SEARCH_TYPE_REHABILITATION,
} from "@recare/core/consts";
import { isFilesActivatedAtAuctionLevel } from "@recare/core/model/auctions";
import { activateRehabFormsPage } from "@recare/core/model/utils/featureFlags";
import { Auction } from "@recare/core/types";
import { useTranslations } from "@recare/translations";
import { NewBadge } from "ds/components/Badge";
import {
  ACCENT_COLOR,
  ERROR_COLOR,
  PRIMARY_COLOR,
  SUCCESS_COLOR,
} from "ds/materials/colors";
import { margin } from "ds/materials/metrics";
import { getTransitionalCareAction } from "dsl/ecosystems/PatientForms/TransitionalCare";
import { PRODUCT_TOURS, tourAttributes } from "dsl/molecules/useProductTour";
import { PatientTabConfig } from ".";

export function useGetPatientTabConfigs({
  auction,
}: {
  auction: Auction;
}): PatientTabConfig[] {
  const translations = useTranslations();

  const transitionalCareAction = getTransitionalCareAction(auction);

  return [
    {
      value: PATIENT_TAB_PROFILE,
      label: translations.patient.patientProfile,
    },
    {
      value: PATIENT_TAB_SEARCH,
      label: translations.search.search,
      disabled: auction?.candidates_status !== CANDIDATES_STATUS_SUCCESS,
      tour: tourAttributes({
        tourKey: PRODUCT_TOURS.search_merge_not_started.key,
        stepKey: PRODUCT_TOURS.search_merge_not_started.steps.search_tab.key,
      }),
    },
    {
      value: PATIENT_TAB_TIMELINE,
      label: translations.timeline.title,
      tour: tourAttributes({
        tourKey: PRODUCT_TOURS.files_page.key,
        stepKey: PRODUCT_TOURS.files_page.steps.documentation_tab.key,
      }),
    },
    {
      value: PATIENT_TAB_TRANSITIONAL_CARE,
      label: translations.patientForms.tabTitle,
      hide: !transitionalCareAction,
      disabled: !!transitionalCareAction?.context?.disabled,
      icon: <NewBadge marginWrapper={margin(0, 0, 0, 0.5)} />,
    },
    {
      value: PATIENT_TAB_INSURANCE,
      label: translations.insuranceApp.eligibility,
      hide: auction.search_type !== SEARCH_TYPE_REHABILITATION,
    },
    {
      value: PATIENT_TAB_REHAB_FORMS,
      label: translations.insuranceApp.eligibility,
      // if isStaging || isDev, show the tab
      hide: !activateRehabFormsPage,
    },
    {
      value: PATIENT_TAB_FILES,
      label: translations.fileSection.tabTitle,
      hide: !isFilesActivatedAtAuctionLevel(auction),
      tour: tourAttributes({
        tourKey: PRODUCT_TOURS.files_page.key,
        stepKey: PRODUCT_TOURS.files_page.steps.files_tab.key,
      }),
    },

    {
      value: PATIENT_TAB_MAP,
      label: translations.patient.map.title,
    },
  ];
}

export const getStatusDotColor = (eligibilityStatus: number | undefined) => {
  switch (eligibilityStatus) {
    case PATIENT_ELIGIBILITY_IN_PROCESS:
      return PRIMARY_COLOR;
    case PATIENT_ELIGIBILITY_APPROVED:
      return SUCCESS_COLOR;
    case PATIENT_ELIGIBILITY_REJECTED:
      return ERROR_COLOR;
    default:
      return null;
  }
};

export const getDotProps = ({
  color,
  disabled,
  value,
}: {
  color: string | null;
  disabled?: PatientTabConfig["disabled"];
  value: PatientTabConfig["value"];
}) => {
  const isSearchStep = value === PATIENT_TAB_SEARCH;

  const isAccentColor = color === ACCENT_COLOR;

  const showDot = !disabled && isSearchStep && isAccentColor;

  return {
    showDot,
    dotColor: color,
  };
};
