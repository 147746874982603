import { useTranslations } from "@recare/translations";
import { VerticalLayout } from "ds/materials/layouts";
import { padding, sizing } from "ds/materials/metrics";
import { GAP, Section } from "dsl/ecosystems/PatientForms/components";
import { AftercareApplication } from "./AftercareApplication";
import { AftercareCarelevel } from "./AftercareCarelevel";
import { AftercareTypes } from "./AftercareTypes";

export const SectionC = () => {
  const translations = useTranslations();
  return (
    <Section
      title={translations.patientForms.transitionalCareForm.sectionC.title}
    >
      <VerticalLayout
        gap={sizing(GAP)}
        padding={padding(GAP, 0, 0)}
        overflow="visible"
      >
        <AftercareTypes />
        <AftercareApplication />
        <AftercareCarelevel />
      </VerticalLayout>
    </Section>
  );
};
