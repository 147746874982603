import { useTranslations } from "@recare/translations";
import Translations from "@recare/translations/types";
import { VerticalLayout } from "ds/materials/layouts";
import { padding } from "ds/materials/metrics";
import {
  DatePickerWithLabel,
  Footnote,
  FootnoteDescription,
  FootnoteLabel,
  GAP,
  Section,
  SectionRow,
  TextInputWithLabel,
} from "dsl/ecosystems/PatientForms/components";

const FOOTNOTE: Footnote = {
  superText: 1,
  description: (translations: Translations) =>
    translations.patientForms.transitionalCareForm.contactInformation
      .footnoteInsuranceNumber,
  label: (translations: Translations) =>
    translations.patientForms.transitionalCareForm.contactInformation
      .patientInsuranceNumber,
};

export const PatientContactSection = () => {
  const translations = useTranslations();
  return (
    <Section
      title={
        translations.patientForms.transitionalCareForm.contactInformation
          .titlePatient
      }
    >
      <SectionRow>
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.contactInformation
              .patientName
          }
          large
          elementName="patient_last_name"
        />
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.contactInformation
              .patientFirstName
          }
          large
          elementName="patient_first_name"
        />
        <DatePickerWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.contactInformation
              .patientBirthDate
          }
          elementName="patient_date_of_birth"
        />
      </SectionRow>

      <SectionRow>
        <TextInputWithLabel
          bold
          label={<FootnoteLabel footnote={FOOTNOTE} />}
          large
          elementName="patient_insurance_number"
        />
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.contactInformation
              .patientStreet
          }
          large
          elementName="patient_location_street"
        />
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.contactInformation
              .patientStreetNumber
          }
          elementName="patient_location_house_number"
        />
      </SectionRow>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.contactInformation
              .patientZipCode
          }
          elementName="patient_location_zipcode"
        />
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.contactInformation
              .patientCity
          }
          large
          elementName="patient_location_city"
        />
      </SectionRow>
      <VerticalLayout
        style={{
          padding: padding(GAP, 0, 0),
          boxSizing: "border-box",
        }}
      >
        <FootnoteDescription footnote={FOOTNOTE} />
      </VerticalLayout>
    </Section>
  );
};
