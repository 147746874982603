import { useTranslations } from "@recare/translations";
import { margin } from "ds/materials/metrics";
import {
  FONT_SIZE_20,
  FONT_WEIGHT_BOLD,
  Subheading,
} from "ds/materials/typography";

export const SectionAHeader = () => {
  const translations = useTranslations();
  return (
    <Subheading
      as="h3"
      margin={margin(0)}
      style={{ fontSize: FONT_SIZE_20, fontWeight: FONT_WEIGHT_BOLD }}
    >
      {
        translations.patientForms.transitionalCareForm
          .titleDocumentationTransitionalCare
      }
    </Subheading>
  );
};
