import {
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
} from "@recare/core/consts";
import {
  Auction,
  QueryProgress,
  TransitionalCareFormData,
  ValueOf,
} from "@recare/core/types";
import { useWarnBeforeExit } from "dsl/hooks/useWarnBeforeExit";
import { useWarnBeforeNavigate } from "dsl/hooks/useWarnBeforeNavigate";
import { ReactNode, useState } from "react";

export type FormGetters = Partial<
  Record<
    keyof TransitionalCareFormData,
    (auction: Auction) => ValueOf<TransitionalCareFormData> | null
  >
>;

export const useImportFormData = ({
  auction,
  formGetters,
  formOnChange,
}: {
  auction: Auction;
  formGetters: FormGetters;
  formOnChange: (value: any, statePath: string, validation: any) => void;
}) => {
  const [queryProgress, setQueryProgress] = useState<QueryProgress>(
    QUERY_PROGRESS_NOT_STARTED,
  );
  const importFormData = () => {
    setQueryProgress(QUERY_PROGRESS_PENDING);
    Object.entries(formGetters).forEach(([field, fieldGetter]) => {
      const value = fieldGetter(auction);
      if (value) {
        formOnChange(value, field, null);
      }
    });
    setQueryProgress(QUERY_PROGRESS_SUCCEED);
    return;
  };
  return { importProgress: queryProgress, importFormData };
};

export const ExitPreventionWrapper = ({
  children,
  dirty,
}: {
  children: ReactNode;
  dirty: boolean;
}) => {
  useWarnBeforeExit({ skip: !dirty });
  useWarnBeforeNavigate({
    skip: !dirty,
  });

  return <>{children}</>;
};
