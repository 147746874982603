import { convertStringToDate } from "@recare/core/model/utils/dates";
import { Auction, GetOntologyType } from "@recare/core/types";
import Translations from "@recare/translations/types";
import { getTime, isValid } from "date-fns";
import { getTransitionalCareDescriptionText } from "./utils";
import { FormGetters } from "dsl/ecosystems/PatientForms/utils";

export const getFormGetters = (
  getOntology: GetOntologyType,
  translations: Translations,
): FormGetters => ({
  clinic_ik_number: (auction: Auction) =>
    auction.patient.careseeker?.banking_details?.ik_number,
  clinic_location_city: (auction: Auction) =>
    auction.patient.careseeker?.address?.city,
  clinic_location_street: (auction: Auction) =>
    auction.patient.careseeker?.address?.street,
  clinic_location_street_number: (auction: Auction) =>
    auction.patient.careseeker?.address?.street_number,
  clinic_location_zipcode: (auction: Auction) =>
    auction.patient.careseeker?.address?.zipcode,
  clinic_name: (auction: Auction) => auction.patient.careseeker?.name,
  legal_representative_last_name: (auction: Auction) =>
    auction.patient.profile?.communication?.guardian_contact_information
      ?.decrypted,
  patient_date_of_birth: (auction: Auction) => {
    const decryptedDate = auction.patient?.profile?.birth_date?.decrypted;

    if (!decryptedDate) return null;

    const birthDate = convertStringToDate(decryptedDate, "de");

    if (!isValid(birthDate)) return null;

    return getTime(birthDate) / 1000;
  },
  patient_first_name: (auction: Auction) =>
    auction.patient?.profile?.first_name?.decrypted,
  patient_insurance_number: (auction: Auction) =>
    auction.patient.profile?.financing?.insurance_number?.decrypted,
  patient_last_name: (auction: Auction) =>
    auction.patient?.profile?.last_name?.decrypted,
  patient_location_city: (auction: Auction) =>
    auction.profile?.search_location?.city,
  patient_location_house_number: (auction: Auction) =>
    auction.profile?.search_location?.encrypted_house_number?.decrypted,
  patient_location_street: (auction: Auction) =>
    auction.profile?.search_location?.street,
  patient_location_zipcode: (auction: Auction) =>
    auction.profile?.search_location?.zipcode,
  prior_treatment_hospital_id: (auction: Auction) =>
    auction.patient.external_id,
  prior_treatment_end_date: (auction: Auction) => auction.start_date,
  transitional_care_start_date: (auction: Auction) => auction.start_date,
  transitional_care_description: (auction: Auction) =>
    getTransitionalCareDescriptionText({ auction, getOntology, translations }),
});
