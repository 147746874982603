import LockIcon from "@mui/icons-material/Lock";
import { useTheme } from "@mui/material";
import { TransitionalCare } from "@recare/core/types";
import { useTranslations } from "@recare/translations";
import { InfoBanner } from "ds/components/InfoBanner";
import { HorizontalLayout } from "ds/materials/layouts";
import { margin } from "ds/materials/metrics";
import {
  Body,
  FONT_SIZE_16,
  FONT_SIZE_20,
  FONT_WEIGHT_BOLD,
  Subheading,
} from "ds/materials/typography";

export function FormExplanationHeader({
  transitionalCare,
}: {
  transitionalCare: Readonly<TransitionalCare> | undefined;
}) {
  const theme = useTheme();
  const translations = useTranslations();
  const isFormSaved = transitionalCare?.created_at;

  return (
    <>
      <Subheading
        as="h3"
        margin={margin(0)}
        style={{ fontSize: FONT_SIZE_20, fontWeight: FONT_WEIGHT_BOLD }}
      >
        {isFormSaved
          ? translations.patientForms.transitionalCareForm.subtitleEditForm
          : translations.patientForms.transitionalCareForm.subtitleNew}
      </Subheading>
      <InfoBanner
        message={
          <>
            <Body as="p" margin={margin(0)} maxWidth="100%">
              {translations.patientForms.transitionalCareForm.createNewBanner}
            </Body>
            <HorizontalLayout aligned margin={margin(1.5, 0)}>
              <LockIcon
                htmlColor={theme.palette.grey[600]}
                style={{
                  fontSize: FONT_SIZE_16,
                  color: theme.palette.grey[600],
                }}
              />
              <Body as="p" margin={margin(0, 1)}>
                {
                  translations.patientForms.transitionalCareForm
                    .encryptionInformation
                }
              </Body>
            </HorizontalLayout>
            <Body as="p" margin={margin(0)} maxWidth="100%" fontWeight={FONT_WEIGHT_BOLD}>
              {translations.patientForms.transitionalCareForm.mandatoryFieldInformation}
            </Body>
          </>
        }
        severity="info"
        wrapperStyle={{ margin: margin(1, 0) }}
      />
    </>
  );
}
