import { useTranslations } from "@recare/translations";
import { optionsToSelectOptions } from "ds/components/SelectInput";
import {
  ActivableToggle,
  SelectWithLabel,
} from "dsl/ecosystems/PatientForms/components";

const OPTION_MIN = 0;
const OPTION_MAX = 20;

const generateProviderCountOptions = () => {
  const options = [];
  for (let i = OPTION_MIN; i <= OPTION_MAX; i++) {
    options.push(i);
  }
  return optionsToSelectOptions(options);
};

export const SuitableProviders = () => {
  const translations = useTranslations();

  return (
    <ActivableToggle
      elementName="transitional_care_suitable_providers_more_than_twenty"
      label={
        translations.patientForms.transitionalCareForm.sectionB
          .toggleNumberOfProviders
      }
      sideMutation={(value, mutate) => {
        if (value)
          return mutate(null, "transitional_care_suitable_providers_count");
      }}
      componentIfFalse={
        <SelectWithLabel
          bold
          elementName="transitional_care_suitable_providers_count"
          label={
            translations.patientForms.transitionalCareForm.sectionB
              .dropdownNumberOfProviders
          }
          options={generateProviderCountOptions()}
          wrapperSx={{ width: "100%" }}
        />
      }
    />
  );
};
