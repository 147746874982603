import { useTranslations } from "@recare/translations";
import Translations from "@recare/translations/types";
import { isBefore, isValid, startOfToday } from "date-fns";
import { WHITE } from "ds/materials/colors";
import { HorizontalLayout } from "ds/materials/layouts";
import { margin, sizing } from "ds/materials/metrics";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import {
  DatePickerWithLabel,
  SMALL_INPUT_WIDTH,
} from "dsl/ecosystems/PatientForms/components";

export const validateHandoverToInsuranceDate = (
  value: number,
  translations: Translations,
) => {
  if (!value || !isValid(value)) {
    return false;
  }

  const selectedDate = new Date(value * 1000); // convert Unix timestamp to JavaScript Date
  const today = startOfToday();

  if (isBefore(selectedDate, today)) {
    return {
      customMessage: translations.patientForms.dateField.warning,
    };
  }
  return true;
};

function HandoverToInsuranceDatePicker() {
  const { isTablet } = useMedia();
  const translations = useTranslations();

  return (
    <DatePickerWithLabel
      bold
      label={translations.patientForms.dateField.title}
      elementName="handover_to_insurance_date"
      textInputVariant="outlined"
      inputSx={{
        width: SMALL_INPUT_WIDTH,
        minWidth: isTablet ? sizing(17.5) : sizing(20),
        background: WHITE,
        margin: margin(0, 0, 1),
      }}
      disablePast
      wrapperSx={{ width: "100%" }}
    />
  );
}

export function SectionHeader({ children }: { children: React.ReactNode }) {
  return (
    <HorizontalLayout
      style={{ justifyContent: "space-between", alignItems: "flex-end" }}
    >
      <HandoverToInsuranceDatePicker />
      {children}
    </HorizontalLayout>
  );
}
