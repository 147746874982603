import { useTranslations } from "@recare/translations";
import { margin } from "ds/materials/metrics";
import {
  ActivableToggle,
  TextAreaWithLabel,
  TEXT_AREA_MAX_CHARACTERS,
  DatePickerWithLabel,
  SMALL_INPUT_WIDTH,
} from "dsl/ecosystems/PatientForms/components";

export const AftercareApplication = () => {
  const translations = useTranslations();
  return (
    <ActivableToggle
      elementName="aftercare_application_submitted"
      label={
        translations.patientForms.transitionalCareForm.sectionC
          .applicationSubmitted
      }
      sideMutation={(value, mutate) => {
        if (!value) {
          return mutate(null, "aftercare_application_date");
        }

        return mutate(null, "aftercare_application_explanation");
      }}
      componentIfFalse={
        <TextAreaWithLabel
          elementName="aftercare_application_explanation"
          label={
            translations.patientForms.transitionalCareForm.sectionC
              .textFieldDescription
          }
          marginOverride={margin(0)}
          multiline
          characterLimit={TEXT_AREA_MAX_CHARACTERS}
        />
      }
      componentIfTrue={
        <DatePickerWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.sectionC
              .applicationDate
          }
          elementName="aftercare_application_date"
          wrapperSx={{ width: "100%" }}
          inputSx={{ width: SMALL_INPUT_WIDTH }}
        />
      }
    />
  );
};
