import { useTranslations } from "@recare/translations";
import { margin } from "ds/materials/metrics";
import { TextAreaWithLabel } from "dsl/ecosystems/PatientForms/components";

export const CHARACTER_LIMIT_DESCRIPTION_TEXT = 780;

export const TransitionalCareDescription = () => {
  const translations = useTranslations();

  return (
    <TextAreaWithLabel
      elementName="transitional_care_description"
      label={
        translations.patientForms.transitionalCareForm.sectionB.textFieldLabel
      }
      marginOverride={margin(0)}
      characterLimit={CHARACTER_LIMIT_DESCRIPTION_TEXT}
      multiline
    />
  );
};
