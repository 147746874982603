import { FormWatcher } from "@recare/react-forms-state";
import { useTranslations } from "@recare/translations";
import CheckboxInputField from "ds/components/CheckboxInputField";
import { margin } from "ds/materials/metrics";
import {
  Section,
  SectionRow,
  TextInputWithLabel,
} from "dsl/ecosystems/PatientForms/components";

export const LegalRepresentationSection = () => {
  const translations = useTranslations();
  return (
    <Section
      title={
        translations.patientForms.transitionalCareForm.contactInformation
          .titleLegalRep
      }
    >
      <CheckboxInputField
        elementName="legal_representative_consent_provided"
        label={
          translations.patientForms.transitionalCareForm.contactInformation
            .confirmedWithLegalRep
        }
        sideMutation={(value, mutate) => {
          if (!value) {
            mutate(null, "legal_representative_last_name");
            mutate(null, "legal_representative_first_name");
            mutate(null, "legal_representative_location_street");
            mutate(null, "legal_representative_location_street_number");
            mutate(null, "legal_representative_location_zipcode");
            mutate(null, "legal_representative_location_city");
          }
        }}
        labelStyleOverride={{ margin: margin(1, -1.375, 0) }}
      />
      <FormWatcher watchPath="legal_representative_consent_provided">
        {({ watchedValue: confirmedWithLegalRep }) => {
          return (
            <>
              <SectionRow>
                <TextInputWithLabel
                  bold
                  label={
                    translations.patientForms.transitionalCareForm
                      .contactInformation.legalRepName
                  }
                  large
                  elementName="legal_representative_last_name"
                  disabled={!confirmedWithLegalRep}
                />
                <TextInputWithLabel
                  bold
                  label={
                    translations.patientForms.transitionalCareForm
                      .contactInformation.legalRepFirstName
                  }
                  large
                  elementName="legal_representative_first_name"
                  disabled={!confirmedWithLegalRep}
                />
              </SectionRow>
              <SectionRow>
                <TextInputWithLabel
                  bold
                  label={
                    translations.patientForms.transitionalCareForm
                      .contactInformation.legalRepStreet
                  }
                  large
                  elementName="legal_representative_location_street"
                  disabled={!confirmedWithLegalRep}
                />
                <TextInputWithLabel
                  bold
                  label={
                    translations.patientForms.transitionalCareForm
                      .contactInformation.legalRepStreetNumber
                  }
                  elementName="legal_representative_location_street_number"
                  disabled={!confirmedWithLegalRep}
                />
                <TextInputWithLabel
                  bold
                  label={
                    translations.patientForms.transitionalCareForm
                      .contactInformation.legalRepZipCode
                  }
                  elementName="legal_representative_location_zipcode"
                  disabled={!confirmedWithLegalRep}
                />
              </SectionRow>

              <SectionRow>
                <TextInputWithLabel
                  bold
                  label={
                    translations.patientForms.transitionalCareForm
                      .contactInformation.legalRepCity
                  }
                  large
                  elementName="legal_representative_location_city"
                  disabled={!confirmedWithLegalRep}
                />
              </SectionRow>
            </>
          );
        }}
      </FormWatcher>
    </Section>
  );
};
