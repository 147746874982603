import { useTranslations } from "@recare/translations";
import {
  DatePickerWithLabel,
  Section,
  SectionRow,
  TextInputWithLabel,
} from "dsl/ecosystems/PatientForms/components";

export const PriorTreatmentSection = () => {
  const translations = useTranslations();
  return (
    <Section
      title={
        translations.patientForms.transitionalCareForm.hospitalStay
          .titlePriorStay
      }
    >
      <SectionRow>
        <DatePickerWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.hospitalStay
              .intakeDate
          }
          elementName="prior_treatment_start_date"
        />
        <DatePickerWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.hospitalStay
              .releaseDate
          }
          elementName="prior_treatment_end_date"
        />
        <TextInputWithLabel
          bold
          label={translations.patientForms.transitionalCareForm.hospitalStay.id}
          elementName="prior_treatment_hospital_id"
          large
        />
      </SectionRow>
    </Section>
  );
};
